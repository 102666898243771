<template>
  <div class="interface-facture">
    <div class="cadre-block-filter">
      <div class="right-block">
        <!-- DATE DEBUT -->
        <div class="date-input-vuetify mr-1" v-if="checkPermission('FSDDF')">
          <v-menu
            v-model="isDatePickerDebutVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_debut"
                label="Date début"
                outlined
                readonly
                hide-details
                clearable
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleDateTimePicker(null, 'date_debut')"
                color="#704ad1"
              ></v-text-field>
            </template>

            <v-date-picker
              v-model="date_debut"
              @input="handleFilter"
              no-title
              locale="fr"
              color="#704ad1"
              header-color="#704ad1"
              event-color="704ad1"
            >
            </v-date-picker>
          </v-menu>
        </div>
        <!-- DATE FIN -->
        <div class="date-input-vuetify mr-1" v-if="checkPermission('FSDDF')">
          <v-menu
            v-model="isDatePickerFinVisible"
            :close-on-content-click="false"
            offset-y
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date_fin"
                label="Date fin"
                outlined
                readonly
                hide-details
                v-on="on"
                v-bind="attrs"
                clearable
                :persistent-placeholder="true"
                placeholder="Sélectionner un date"
                prepend-inner-icon="mdi-calendar"
                @click:clear="handleDateTimePicker(null, 'date_fin')"
                color="#704ad1"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date_fin"
              @input="handleFilter"
              no-title
              locale="fr"
              color="#704ad1"
              header-color="#704ad1"
              event-color="#704ad1"
            ></v-date-picker>
          </v-menu>
        </div>
        <!-- UPLOAD FILE -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on }">
            <div v-on="on">
              <input-file-excel-num-dossier
                v-show="true"
                :value="nums"
                @searchWithNumDossier="searchWithMultipleFacture"
                :fetchFuntion="fecthFactureNumbersInExcelFile"
                :showCount="true"
                hiddenContent="true"
              />
            </div>
          </template>
          <span>Choisir un fichier</span>
        </v-tooltip>
        <!-- COONTENT LOADING AND MESSAGE ERROR -->
        <!-- <div
          v-if="loadingFactureComptabilite"
          class="chargement chargement-loading-icon"
        >
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div> -->
        <v-progress-circular
          v-if="loadingFactureComptabilite"
          indeterminate
          class="ml-2"
          color="#704ad1"
        ></v-progress-circular>
        <div class="notif-export-file-sci ml-2" v-if="getShowNotifExportFile">
          Préparation du fichier en cours ...
        </div>
        <div class="error-message ml-2 mr-2">
          <div v-if="errorFactures" class="error">
            <ul v-if="Array.isArray(errorFactures)" class="mb-0">
              <li v-for="(e, index) in errorFactures" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ errorFactures }}</div>
          </div>
        </div>
      </div>
      <div class="left-block">
        <!-- SEARCH -->
        <div class="serach-input mr-2">
          <search
            v-if="checkPermission('REFFTHR')"
            :value="searchValue"
            @changeSearchValue="changeSearchValue"
            :hiddenContent="true"
          />
        </div>
        <!--REFRESH FACTURE-->
        <refresh
          :dataToUse="computedCheckFactureSelected"
          :filter="computedFilter"
          @refreshLoader="handlerefreshLoader"
        />
        <!-- FILTER -->
        <v-tooltip bottom color="#311B92">
          <template v-slot:activator="{ on, attrs }">
            <div
              v-if="
                checkPermission('REFFTHFV') ||
                  checkPermission('REFFTHFA') ||
                  checkPermission('REFFTHFF') ||
                  checkPermission('REFFTHFC') ||
                  checkPermission('REFFTHFS') ||
                  checkPermission('REFFTHFT') ||
                  checkPermission('REFFTHFCO') ||
                  checkPermission('REFFTHFL')
              "
              size="sm"
              v-on="on"
              v-bind="attrs"
              @click="openPopover"
              class="block-icon mr-2 button-height-unset"
            >
              <IconFiltre></IconFiltre>
            </div>
          </template>
          <span>Choisir un Filtre</span>
        </v-tooltip>
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="350"
          origin="top right"
          content-class="popover-menu-refresh-facture"
        >
          <v-card>
            <v-divider class="line-hr-filter"></v-divider>
            <v-card-text class="body-card filter-content-popup">
              <v-form>
                <!-- SOCIETE -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('REFFTHFV')"
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="isLocalStorageVendeur"
                  :items="[...computedGetVendeurs]"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Société"
                  return-object
                  class="mb-1"
                  item-text="text"
                  dense
                  item-value="value"
                  :auto-focus="false"
                  no-data-text="Aucune société trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageVendeur.length - 0 }} société(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- CLIENT -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('REFFTHFA')"
                  v-model="isLocalStorageAcheteur"
                  :items="computedGetAcheteurs"
                  item-text="text"
                  item-value="value"
                  color="#704ad1"
                  class="mb-1"
                  item-color="#704ad1"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Client"
                  required
                  return-object
                  dense
                  :auto-focus="false"
                  no-data-text="Aucun client trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageAcheteur.length - 0 }} client(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- FAMILLE -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('REFFTHFF')"
                  v-model="isLocalStorageFamille"
                  :items="computedListFamille"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  multiple
                  color="#704ad1"
                  item-color="#704ad1"
                  hide-details
                  class="mb-1"
                  label="Famille"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun famille trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageFamille.length - 0 }} famille(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- CATEGORIE -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('REFFTHFC')"
                  v-model="isLocalStorageCategorie"
                  :items="computedGetCategories"
                  item-text="text"
                  item-value="value"
                  color="#704ad1"
                  item-color="#704ad1"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Catégorie"
                  class="mb-1"
                  return-object
                  required
                  dense
                  :auto-focus="false"
                  no-data-text="Aucun catégorie trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageCategorie.length - 0 }} catégorie(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- STATUTS -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('REFFTHFS')"
                  v-model="isLocalStorageStatut"
                  :items="ListStatut"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  item-color="#704ad1"
                  color="#704ad1"
                  label="Statut"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun statut trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      (+{{ isLocalStorageStatut.length - 0 }} Statuts)
                    </span>
                  </template>
                </v-autocomplete>
                <!-- TYPE -->
                <v-autocomplete
                  :clearable="true"
                  v-if="checkPermission('REFFTHFT')"
                  v-model="isLocalStorageType"
                  :items="ListType"
                  item-text="text"
                  item-value="value"
                  return-object
                  dense
                  outlined
                  hide-details
                  class="mb-1"
                  item-color="#704ad1"
                  color="#704ad1"
                  label="Type"
                  multiple
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun type trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ isLocalStorageType.length - 0 }} type(s)
                    </span>
                  </template>
                </v-autocomplete>
                <!--COMPTABILISE-->
                <v-select
                  :clearable="true"
                  v-if="checkPermission('COTHFCO')"
                  v-model="isLocalStorageComptabilise"
                  :items="computedComptabilisé"
                  item-text="text"
                  item-value="value"
                  dense
                  outlined
                  hide-details
                  class="mb-4"
                  label="Comptabilisé"
                  item-color="#704ad1"
                  color="#704ad1"
                  :persistent-placeholder="true"
                  placeholder="Sélectionnez"
                  no-data-text="Aucun élément trouvé"
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-select>
              </v-form>
            </v-card-text>
            <v-card-actions class="popover-action-filter">
              <v-btn
                class="ma-2 capitalize-text"
                outlined
                color="#5E35B1"
                @click="validerPopover"
              >
                Appliquer
              </v-btn>
              <v-btn
                class="ma-2 ml-2 capitalize-text"
                text
                @click="closePopover"
              >
                Annuler
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-menu>
      </div>
    </div>
    <div class="content-cadre-table-compta">
      <!-- BLOCK SELON FILTER SELECTIONNEE -->
      <div>
        <div class="block-filter-style ml-0 mb-2" v-if="returnTableChips">
          <span class="phrase-style">Les filtres choisie sont: </span>
          <span class="item-filter-style" v-if="date_debut !== null"
            >Date début : {{ date_debut }}</span
          >
          <span class="item-filter-style" v-if="date_fin !== null"
            >Date fin : {{ date_fin }}</span
          >
          <span v-if="searchValue" class="item-filter-style"
            >Recherche : {{ searchValue }}</span
          >
          <span v-if="comptabilite !== null" class="item-filter-style"
            >Comptabilisé : {{ comptabilite }}</span
          >
        </div>
      </div>
      <!-- BLOCK SELON FILTER POPOVER SELECTIONNEE -->
      <v-row class="filter-Block-icon" v-if="returnTableFiltre">
        <!-- SOCIETE -->
        <v-col
          :cols="computedLengthFilter"
          v-if="vendeur.length"
          class="space-table-filter-refresh-fac"
        >
          <span class="title-block-chip-filter ml-3">Société : </span>
          <v-sheet elevation="1" class="py-0 px-1 ml-3">
            <v-chip-group mandatory>
              <v-chip
                :key="v.value"
                v-for="v in vendeur"
                close
                @click:close="
                  remove(v, 'vendeur', 'handleFilter', 'isLocalStorageVendeur')
                "
                x-small
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ v.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- CLIENT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="acheteur.length"
          class="space-table-filter-refresh-fac"
        >
          <span class="title-block-chip-filter">Client : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="a.text + 'proprietaire'"
                v-for="a in acheteur"
                close
                x-small
                @click:close="
                  remove(
                    a,
                    'acheteur',
                    'handleFilter',
                    'isLocalStorageAcheteur'
                  )
                "
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ a.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
        <!-- FAMILLE -->
        <v-col
          :cols="computedLengthFilter"
          v-if="famille.length"
          class="space-table-filter-refresh-fac"
        >
          <span class="title-block-chip-filter">Famille : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="a + 'fam'"
                v-for="a in famille"
                close
                x-small
                @click:close="
                  remove(a, 'famille', 'handleFilter', 'isLocalStorageFamille')
                "
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ a.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>

        <!-- categorie -->
        <v-col
          :cols="computedLengthFilter"
          v-if="categorie.length"
          class="space-table-filter-refresh-fac"
        >
          <span class="title-block-chip-filter">Catégorie : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="a.text"
                v-for="a in categorie"
                close
                x-small
                @click:close="
                  remove(
                    a,
                    'categorie',
                    'handleFilter',
                    'isLocalStorageCategorie'
                  )
                "
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ a.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>

        <!-- STATUT -->
        <v-col
          :cols="computedLengthFilter"
          v-if="statut.length"
          class="space-table-filter-refresh-fac"
        >
          <span class="title-block-chip-filter">Statut : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="a.text"
                v-for="a in statut"
                close
                x-small
                @click:close="
                  remove(a, 'statut', 'handleFilter', 'isLocalStorageStatut')
                "
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ a.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>

        <!-- TYPE -->
        <v-col
          :cols="computedLengthFilter"
          v-if="type.length"
          class="space-table-filter-refresh-fac"
        >
          <span class="title-block-chip-filter">Type : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="a.text"
                v-for="a in type"
                close
                x-small
                @click:close="
                  remove(a, 'type', 'handleFilter', 'isLocalStorageType')
                "
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ a.text }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>

        <!-- Lot -->
        <v-col
          :cols="computedLengthFilter"
          v-if="mail_lot.length"
          class="space-table-filter-refresh-fac"
        >
          <span class="title-block-chip-filter">Lot : </span>
          <v-sheet elevation="1" class="py-0 px-1">
            <v-chip-group mandatory>
              <v-chip
                :key="a"
                v-for="a in mail_lot"
                close
                x-small
                @click:close="
                  remove(a, 'mail_lot', 'handleFilter', 'isLocalStorageMailLot')
                "
                color="#f1edfb"
                text-color="rgb(112, 74, 209)"
              >
                {{ a }}
              </v-chip>
            </v-chip-group>
          </v-sheet>
        </v-col>
      </v-row>
      <!-- TABLE FACTURE LIBRE VUE PAR DEFAUT -->
      <div
        class="table-refresh-fac"
        :class="{
          'padding-table': returnTableFiltre
        }"
      >
        <v-data-table
          v-model="selectedTable"
          :headers="computedFields"
          :items="factures"
          :class="{
            'height-filter-chips-table': returnTableChips && !returnTableFiltre,
            'height-filter-table': returnTableFiltre && !returnTableChips,
            'height-filter-tous': returnTableChips && returnTableFiltre
          }"
          @item-expand="factures"
          class="table-facture-compta"
          hide-default-footer
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          :calculate-widths="false"
          :fixed-header="true"
          :items-per-page="perPage"
          hide-action
          :no-data-text="
            loadingFactureComptabilite
              ? 'Chargement en cours ...'
              : `Il n'y a aucune donnée à afficher dans ce tableau.`
          "
        >
          <!-- HEADERS TABLE -->
          <template v-slot:[`header.check_all`]="{ header }">
            <td>
              <span v-if="header.value != 'check_all'"> {{ header }}</span>
              <div v-else>
                <v-checkbox
                  class="checked-global"
                  v-model="checkAll"
                  @change="checkAllFunction"
                  color="#704ad1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
          </template>
          <!-- BODY TABLE -->
          <template v-slot:[`item.check_all`]="{ item }">
            <td class="custom-cell sticky-header-1">
              <v-checkbox
                class="checked-groupe"
                v-model="item.check"
                @change="checkRows(item)"
                color="#704ad1"
                :true-value="true"
                :false-value="false"
                hide-details
              ></v-checkbox>
            </td>
          </template>
          <!--ROWS DETAILS-->
          <!-- <template v-slot:[`item.data-table-expand`]="{ item }">
            <td class="custom-cell sticky-header-2">
              <button
                class="btn btn-anomalie"
                v-if="item && item.factureAvoir && item.factureAvoir.length > 0"
                @click="showRowDetails(item)"
              >
                <font-awesome-icon
                  class="icon-plus"
                  icon="plus-square"
                  color="#704ad1"
                  title="Afficher Facture(s) Avoir(s)"
                  v-if="item.showDetails == false"
                />
                <font-awesome-icon
                  class="icon-plus"
                  icon="minus-square"
                  color="#704ad1"
                  title="Masquer Facture(s) Avoir(s)"
                  v-if="item.showDetails == true"
                />
              </button>
            </td>
          </template> -->
          <!--CLIENT-->
          <template v-slot:[`item.Vendeur`]="{ item }">
            <td class="custom-cell">{{ item.Vendeur }}</td>
          </template>
          <!--TYPE-->
          <template v-slot:[`item.type`]="{ item }">
            <td class="custom-cell">{{ item.type }}</td>
          </template>
          <!--SOCIETE-->
          <template v-slot:[`item.famille`]="{ item }">
            <td class="custom-cell">{{ item.famille }}</td>
          </template>

          <!--NUM-->
          <template v-slot:[`item.num`]="{ item }">
            <td class="custom-cell">
              <div class="flex-div">
                {{ item.num }}
                <VisualisationFacture
                  :awesome="true"
                  :facture="item.id"
                  :permission="checkPermission('COTHTVF')"
                ></VisualisationFacture>
                <div
                  v-if="checkPermission('COTHTFACT')"
                  class="block-icon-table-facture ml-1 mr-1"
                  @click.prevent="downloadFacture(item.id)"
                  title="Télécharger facture"
                >
                  <font-awesome-icon icon="arrow-alt-circle-down" />
                </div>
              </div>
            </td>
          </template>
          <!--REFERENCE-->
          <template v-slot:[`item.reference`]="{ item }">
            <td class="custom-cell">
              <div class="flex-div" v-if="item.numero_document_corrige">
                {{ item.numero_document_corrige }}
                <VisualisationFacture
                  v-if="item.origine_id"
                  :awesome="true"
                  :facture="item.origine_id"
                  :permission="checkPermission('COTHTVF')"
                ></VisualisationFacture>
                <div
                  v-if="item.origine_id && checkPermission('COTHTFACT')"
                  class="block-icon-table-facture ml-1 mr-1"
                  @click.prevent="downloadFacture(item.origine_id)"
                  title="Télécharger facture"
                >
                  <font-awesome-icon icon="arrow-alt-circle-down" />
                </div>
              </div>
              <div v-else>-</div>
            </td>
          </template>
          <!--DATE CREATION-->
          <template v-slot:[`item.date_creation`]="{ item }">
            <td class="custom-cell">
              {{ formateDateGetters(item.date_creation) }}
            </td>
          </template>
          <!--DATE CREATION REELLE-->
          <template v-slot:[`item.date_relle`]="{ item }">
            <td class="custom-cell">
              {{ formateDateGetters(item.created_at) }}
            </td>
          </template>
          <!--SOCIETE-->
          <template v-slot:[`item.Acheteur`]="{ item }">
            <td class="custom-cell">{{ item.Acheteur }}</td>
          </template>
          <!--ETAT-->
          <template v-slot:[`item.etat`]="{ item }">
            <td class="custom-cell">{{ item.etat }}</td>
          </template>
          <!--DATE PAIEMENT-->
          <template v-slot:[`item.date_paiement`]="{ item }">
            <td class="custom-cell">
              {{ formateDateGetters(item.date_paiement) }}
            </td>
          </template>
          <!--CATEGORIE-->
          <template v-slot:[`item.categorie`]="{ item }">
            <td class="custom-cell">
              {{ item.categorie ? item.categorie : '-' }}
            </td>
          </template>
          <!--TOTAL NET HT-->
          <template v-slot:[`item.total_net_ht`]="{ item }">
            <td class="custom-cell">{{ item.total_net_ht }} €</td>
          </template>
          <!--TOTAL TVA-->
          <template v-slot:[`item.total_tva`]="{ item }">
            <td class="custom-cell">{{ item.total_tva }} €</td></template
          >
          <!--TOTAL TTC-->
          <template v-slot:[`item.total_ttc`]="{ item }">
            <td class="custom-cell">{{ item.total_ttc }} €</td></template
          >
          <!--DATE PAIEMENT-->
          <template v-slot:[`item.comptabilise`]="{ item }">
            <td class="custom-cell">
              {{ item.comptabilise ? item.comptabilise : '-' }}
            </td></template
          >
          <!--STATUT CLIENT-->
          <template v-slot:[`item.mail_lot`]="{ item }">
            <td class="custom-cell">
              {{ item.mail_lot ? item.mail_lot : '-' }}
            </td></template
          >
          <!-- EXPANDED TABLE -->
          <!-- <template #expanded-item="{ headers,item }">
            <td
              class="td-expanded"
              :colspan="headers.length"
              v-if="
                item &&
                  item.factureAvoir &&
                  item.factureAvoir.length > 0 &&
                  item.showDetails == true
              "
            >
              <table :items="item.factureAvoir" class="table-expanded">
                <tbody>
                  <tr v-for="data in item.factureAvoir" :key="data.id">
                    <td
                      class="td-expanded sticky-header-1"
                      style="width:40px  !important ; min-width:40px !important"
                    >
                      <v-checkbox
                        class="checked-expanded"
                        v-model="data.checkAvoir"
                        color="#704ad1"
                        :value="true"
                        :unchecked-value="false"
                        hide-details
                      ></v-checkbox>
                    </td>
                    <td
                      class="td-expanded sticky-header-2"
                      style="width:40px  !important ; min-width:40px !important"
                    >
                      <span></span>
                    </td>
                    <td class="td-expanded" v-if="checkPermission('REFFTHACV')">
                      {{ data.vendeur }}
                    </td>
                    <td v-if="checkPermission('REFFTHACT')" class="td-expanded">
                      {{ data.type }}
                    </td>
                    <td class="td-expanded" v-if="checkPermission('REFFTHACF')">
                      {{ data.famille }}
                    </td>
                    <td class="td-expanded" v-if="checkPermission('REFFTHACN')">
                      <div class="flex-div">
                        {{ data.num }}
                        <VisualisationFacture
                          :awesome="true"
                          :facture="data.id"
                          :permission="checkPermission('COTHTVF')"
                        ></VisualisationFacture>
                        <div
                          v-if="checkPermission('COTHTFACT')"
                          class="block-icon-table-facture ml-1 mr-1"
                          @click.prevent="downloadFacture(data.id)"
                          title="Télécharger facture"
                        >
                          <font-awesome-icon icon="arrow-alt-circle-down" />
                        </div>
                      </div>
                    </td>
                    <td
                      class="td-expanded"
                      v-if="checkPermission('REFFTHACDDC')"
                    >
                      {{ formateDateGetters(data.date_creation) }}
                    </td>
                    <td class="td-expanded" v-if="checkPermission('REFFTHACA')">
                      {{ data.acheteur }}
                    </td>
                    <td v-if="checkPermission('REFFTHACE')" class="td-expanded">
                      {{ data.etat }}
                    </td>
                    <td
                      class="td-expanded"
                      v-if="checkPermission('REFFTHACDDP')"
                    >
                      {{ formateDateGetters(data.date_paiement) }}
                    </td>
                    <td class="td-expanded" v-if="checkPermission('REFFTHAC')">
                      {{
                        data.categorie && data.categorie.nom_categorie
                          ? data.categorie.nom_categorie
                          : '-'
                      }}
                    </td>
                    <td
                      class="td-expanded"
                      v-if="checkPermission('REFFTHACNETHT')"
                    >
                      {{ data.total_net_ht }} {{ data.devise }}
                    </td>
                    <td
                      class="td-expanded"
                      v-if="checkPermission('REFFTHACTVA')"
                    >
                      {{ data.total_tva }}
                    </td>
                    <td
                      class="td-expanded"
                      v-if="checkPermission('REFFTHACTTC')"
                    >
                      {{ data.total_ttc }} {{ data.devise }}
                    </td>
                    <td
                      class="td-expanded"
                      v-if="checkPermission('REFFTHACCOM')"
                    >
                      {{ item.comptabilise }}
                    </td>
                    <td
                      class="td-expanded"
                      v-if="checkPermission('REFFTHACNL')"
                    >
                      {{ item.mail_lot }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </template> -->
        </v-data-table>
      </div>
    </div>
    <div class="footer-style-table">
      <div
        v-if="computedTotalHt || computedTotalTtc"
        class="style-somme-th-ttc pl-2"
      >
        <div>
          <span class="bold-text"> Total NET HT:</span>
          {{   computedTotalHt
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                .replaceAll(',', ' '), }}
          € {{ '   ' }} <span class="bold-text">Total TTC:</span>

          {{
            computedTotalTtc
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              .replaceAll(',', ' ')
          }}
          € {{ '   ' }}
        </div>
      </div>
      <div class="text-center pagination-table">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify">
        <v-select
          v-model="perPage"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          color="#704ad1"
          item-color="#704ad1"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetY: true,
            closeOnClick: true,
            closeOnContentClick: true
          }"
        ></v-select>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import Refresh from './component/refresh.vue';

export default {
  data() {
    return {
      nums: null,
      menu: false,
      singleExpand: false,
      isDatePickerDebutVisible: false,
      isDatePickerFinVisible: false,
      expanded: [],
      selectedTable: [],
      ListStatut: [
        { value: 'Créé', text: 'Créé' },
        { value: 'Payé', text: 'Payé' },
        { value: 'A payer', text: 'A payer' },
        { value: 'Payé en partie', text: 'Payé en partie' },
        { value: 'Annulée', text: 'Annulée' }
      ],
      ListType: [
        { value: 'Facture', text: 'Facture' },
        // { value: 'Facture proforma', text: 'Facture proforma' },
        { value: "Facture d'avoir", text: "Facture d'avoir" }
      ],
      perPageList: [
        { value: 100, text: 100 },
        { value: 200, text: 200 },
        { value: 300, text: 300 }
      ],
      acheteur: [],
      famille: [],
      statut: [],
      type: [],
      vendeur: [],
      categorie: [],
      date_debut: null,
      date_fin: null,
      isOpen: false,
      page: 1,
      perPage: 100,
      column: 'id',
      order: 'DESC',
      searchValue: null,
      fam: 'all',
      checkAll: false,
      comptabilite: null,
      mail_lot: [],
      isLocalStorageAcheteur: [],
      isLocalStorageVendeur: [],
      isLocalStorageFamille: [],
      isLocalStorageCategorie: [],
      isLocalStorageStatut: [],
      isLocalStorageType: [],
      isLocalStorageMailLot: [],
      isLocalStorageComptabilise: null
    };
  },
  computed: {
    ...mapGetters([
      'getFiliaeOfResponsable',
      'getAllCategories',
      'getSettingFilialesTh',
      'loadingFactureComptabilite',
      'getRows',
      'errorFactures',
      'factures',
      'checkPermission',
      'familles',
      'checkPermission',
      'typesCompta',
      'getShowNotifExportFile',
      'getLotsCompta',
      'formateDateGetters',
      'countNonEmptyArrays'
    ]),
    computedLengthFilter() {
      return this.countNonEmptyArrays(
        this.vendeur,
        this.acheteur,
        this.famille,
        this.categorie,
        this.statut,
        this.type,
        this.mail_lot
      );
    },
    returnTableChips() {
      if (
        this.date_debut !== null ||
        this.date_fin !== null ||
        this.searchValue !== null ||
        this.comptabilite !== null
      ) {
        return true;
      }
      return false;
    },
    returnTableFiltre() {
      if (
        this.vendeur.length ||
        this.acheteur.length ||
        this.famille.length ||
        this.categorie.length ||
        this.statut.length ||
        this.type.length ||
        this.mail_lot.length
      ) {
        return true;
      }
      return false;
    },
    computedCheckFactureSelected() {
      let tab = [];
      this.factures.forEach(fact => {
        if (fact.check == true) {
          tab.push(fact);
        }
        // fact.factureAvoir.forEach(a => {
        //   if (a.checkAvoir == true) {
        //     tab.push(a);
        //   }
        // });
      });
      return tab;
    },
    totalPages() {
      if (this.getRows) {
        return Math.ceil(this.getRows / this.perPage);
      }
      return this.getRows;
    },
    computedComptabilisé() {
      let types = [];
      types.push({
        value: null,
        text: 'Tous'
      });
      for (let i = 0; i < this.typesCompta.length; i++) {
        types.push({
          value: this.typesCompta[i],
          text: this.typesCompta[i]
        });
      }

      return types;
    },
    computedListFamille() {
      let familles = [];
      for (let i = 0; i < this.familles.length; i++) {
        familles.push({
          value: this.familles[i],
          text: this.familles[i]
        });
      }
      return familles;
    },
    computedFields() {
      let fields = [
        {
          value: 'check_all',
          text: '',
          show: true,
          sortable: false,
          width: '40px !important',
          class: 'sticky-header-1'
        },
        // {
        //   value: 'data-table-expand',
        //   text: '',
        //   show: true,
        //   sortable: false,
        //   width: '40px !important',
        //   class: 'sticky-header-2'
        // },

        {
          value: 'Vendeur',
          text: 'SOCIÉTÉ',
          editable: false,
          show: this.checkPermission('REFFTHACV'),
          sortable: true
        },
        {
          value: 'type',
          text: 'TYPE',
          show: this.checkPermission('REFFTHACT'),
          sortable: true
        },
        {
          value: 'famille',
          text: 'FAMILLE',
          show: this.checkPermission('REFFTHACF'),
          sortable: true
        },
        {
          value: 'num',
          text: 'NUMERO',
          show: this.checkPermission('REFFTHACN'),

          sortable: true
        },
        {
          value: 'reference',
          text: 'RÉFERENCE',
          show: true,
          sortable: true
        },
        {
          value: 'date_creation',
          text: 'DATE DE CRÉATION',
          show: this.checkPermission('REFFTHACDDC'),
          sortable: true
        },
        {
          value: 'date_relle',
          text: 'DATE DE CRÉATION RÉELLE',
          show: true,
          sortable: true
        },
        {
          value: 'Acheteur',
          text: 'CLIENT',
          editable: false,
          show: this.checkPermission('REFFTHACA'),
          sortable: true
        },
        {
          value: 'etat',
          text: 'ETAT',
          show: this.checkPermission('REFFTHACE'),
          sortable: true
        },
        {
          value: 'date_paiement',
          text: 'DATE DE PAIEMENT',
          show: this.checkPermission('REFFTHACDDP'),
          sortable: true
        },
        {
          value: 'categorie',
          text: 'CATEGORIE',
          show: this.checkPermission('REFFTHAC'),
          sortable: true
        },
        {
          value: 'total_net_ht',
          text: 'NET HT',
          show: this.checkPermission('REFFTHACNETHT'),
          sortable: true
        },
        {
          value: 'total_tva',
          text: 'TVA',
          show: this.checkPermission('REFFTHACTVA'),
          sortable: true
        },
        {
          value: 'total_ttc',
          text: 'TOTAL TTC',
          show: this.checkPermission('REFFTHACTTC'),
          sortable: true
        },
        {
          value: 'comptabilise',
          text: 'Comptabilisé',
          show: this.checkPermission('REFFTHACCOM'),
          sortable: true
        },
        {
          value: 'mail_lot',
          text: 'Numéro de lot',
          show: this.checkPermission('REFFTHACNL'),
          sortable: true
        }
      ];
      return fields.filter(item => item.show == true);
    },
    computedCheckRows() {
      let selected = [];
      this?.factures?.map(facture => {
        if (facture.check == true) {
          // if (
          //   facture &&
          //   facture.factureAvoir &&
          //   facture.factureAvoir.length > 0
          // ) {
          //   facture.factureAvoir.map(item => {
          //     item.checkAvoir = facture.check;
          //   });
          // }
          selected.push(facture.id);
        }
      });
      return selected;
    },
    computedTotalHt() {
      let sum = 0;
      let sumAvoir = 0;
      this.computedCheckRowsSelected.map(item => {
        sum += item.net_ht;
        // for (let i = 0; i < item.factureAvoir.length; i++) {
        //   sumAvoir += item.factureAvoir[i].total_ht;
        // }
      });
      return Math.round(sum * 100) / 100 - Math.round(sumAvoir * 100) / 100;
    },
    computedTotalTtc() {
      let sum = 0;
      let sumAvoir = 0;
      this.computedCheckRowsSelected.map(facture => {
        sum += facture.ttc;
        // for (let i = 0; i < facture.factureAvoir.length; i++) {
        //   sumAvoir += parseFloat(
        //     facture.factureAvoir[i].total_ttc.replace(' ', '')
        //   );
        // }
      });
      return Math.round(sum * 100) / 100 - Math.round(sumAvoir * 100) / 100;
    },
    computedCheckRowsSelected() {
      let selected = [];
      this?.factures?.map(facture => {
        if (facture.check == true) {
          selected.push(facture);
        }
      });
      return selected;
    },
    computedGetAcheteurs() {
      let acheteurNames = this.getSettingFilialesTh.map(item => {
        if (item.type == 'particulier') {
          return {
            value: item.id,
            text: item.name_part
          };
        }
        return { value: item.id, text: item.name };
      });
      return acheteurNames;
    },
    computedGetVendeurs() {
      let vendeurNames = this.getFiliaeOfResponsable.map(item => {
        return { value: item.id, text: item.name };
      });
      return vendeurNames;
    },
    computedGetCategories() {
      let categoriesNames = this.getAllCategories.map(item => {
        return { value: item.id, text: item.nom_categorie };
      });
      return categoriesNames;
    },
    computedFilter() {
      let params = { famille: this.fam };

      if (this.date_debut != null) {
        params.date_debut = this.date_debut;
      }
      if (this.date_fin != null) {
        params.date_fin = this.date_fin;
      }
      if (this.statut.length) {
        params.etat = this.statut;
      }
      if (this.type.length) {
        params.type = this.type;
      }
      if (this.famille.length) {
        params.family = this.famille;
      }
      if (this.vendeur.length) {
        params.vendeur_id = this.vendeur;
      }
      if (this.acheteur.length) {
        params.acheteur_id = this.acheteur;
      }
      if (this.categorie.length) {
        params.categorie_id = this.categorie;
      }
      if (this.mail_lot.length) {
        params.mail_lot = this.mail_lot;
      }
      if (this.comptabilite != null) {
        params.comptabilite = this.comptabilite;
      }
      if (this.searchValue != null) {
        params.num = this.searchValue;
      }

      return params;
    }
  },
  methods: {
    ...mapActions([
      'changeCompta',
      'getSettingFilialeTh',
      'getCategoriesFactureLibre',
      'getFilialsOfConnectedResponsable',
      'getFactures',
      'exportFactureLibre',
      'downloadZIP',
      'downloadPDF',
      'getFamilles',
      'getTypeOfCompta',
      'getLotCompta',
      'fecthFactureNumbersInExcelFile'
    ]),
    handlerefreshLoader(data) {
      this.refreshLoader = data;
    },
    searchWithMultipleFacture(value) {
      if (this.nums != value) {
        this.nums = value;
        this.handleFilter();
      }
    },
    showRowDetails(item) {
      item.showDetails = !item.showDetails;
      if (item.showDetails == true) {
        if (!this.expanded.includes(item)) {
          this.expanded.push(item);
        }
      }
    },
    handleDateTimePicker(value, name) {
      this[name] = value;
      this.handleFilter();
    },
    async changePerPage() {
      this.page = 1;
      this.handleFilter();
    },
    openPopover() {
      this.menu = true;
    },
    validerPopover() {
      //
      //SET POPOVER DATA
      //
      this.vendeur = this.isLocalStorageVendeur;
      this.acheteur = this.isLocalStorageAcheteur;
      this.famille = this.isLocalStorageFamille;
      this.categorie = this.isLocalStorageCategorie;
      this.statut = this.isLocalStorageStatut;
      this.type = this.isLocalStorageType;
      this.mail_lot = this.isLocalStorageMailLot;
      this.comptabilite = this.isLocalStorageComptabilise;
      //
      //END SET POPOVER DATA
      //
      this.handleFilter();
      this.menu = false;
    },
    closePopover() {
      this.menu = false;
      this.vendeur = [];
      this.isLocalStorageVendeur = [];
      this.acheteur = [];
      this.isLocalStorageAcheteur = [];
      this.type = [];
      this.isLocalStorageType = [];
      this.categorie = [];
      this.isLocalStorageCategorie = [];
      this.mail_lot = [];
      this.isLocalStorageMailLot = [];
      this.famille = [];
      this.isLocalStorageFamille = [];
      this.comptabilite = null;
      this.isLocalStorageComptabilise = null;
      this.statut = [];
      this.isLocalStorageStatut = [];
      this.handleFilter();
    },
    handleFetchLot() {
      this.getLotCompta({
        date_debut: this.date_debut,
        date_fin: this.date_fin
      });
    },
    duplicateFactureCompta(id) {
      this.$router.push(`/duplicate-facture/${id}`);
    },
    remove(item, filter, fct, origin) {
      this[filter].splice(this[filter].indexOf(item), 1);
      if (origin) {
        this[origin] = this[filter];
      }
      this[fct]();
    },
    downloadFacture(id) {
      this.downloadPDF({ id: id });
    },
    handleResetCheck(value) {
      if (value == false) {
        this.resetCheck();
      }
    },
    resetCheck() {
      this.checkAll = false;
      this?.factures?.map(facture => {
        facture.check = false;
        // if (
        //   facture.factureAvoir &&
        //   facture.factureAvoir.length &&
        //   facture.factureAvoir.length > 0
        // ) {
        //   facture.factureAvoir.map(fact => {
        //     fact.checkAvoir = false;
        //   });
        // }
      });
    },
    checkRows(item) {
      let checked = [];
      if (item.check == false && this.checkAll == true) {
        this.checkAll = false;
      }
      this?.factures?.map(facture => {
        if (facture.check == true) {
          checked.push(facture);
        }
        // if (
        //   facture.factureAvoir &&
        //   facture.factureAvoir.length &&
        //   facture.factureAvoir.length > 0
        // ) {
        //   facture.factureAvoir.map(fact => {
        //     fact.checkAvoir = facture.check;
        //   });
        // }
      });
      if (checked.length == this.factures.length) {
        this.checkAll = true;
      }
    },
    checkAllFunction() {
      this.factures.map(facture => {
        facture.check = this.checkAll;
        // if (
        //   facture.factureAvoir &&
        //   facture.factureAvoir.length &&
        //   facture.factureAvoir.length > 0
        // ) {
        //   facture.factureAvoir.map(fact => {
        //     fact.checkAvoir = this.checkAll;
        //   });
        // }
      });
    },
    disabledStartDate(date) {
      return (
        this.date_fin && moment(date, 'YYYY-MM-DD') >= new Date(this.date_fin)
      );
    },
    disabledEndDate(date) {
      return (
        this.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') < new Date(this.date_debut)
      );
    },
    changeSearchValue(event) {
      this.searchValue = event;
      this.handleFilter();
    },
    async handleFilter(fetchFilter) {
      if (fetchFilter) {
        this.handleFetchLot();
      }
      this.resetCheck();
      this.setLocalStorageComptabilite();
      this.$nextTick(async () => {
        await this.getFactures({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          etat: this.statut,
          type: this.type,
          family: this.famille,
          vendeur_id: this.vendeur,
          acheteur_id: this.acheteur,
          categorie_id: this.categorie,
          per_page: this.perPage,
          page: this.page,
          order: this.order,
          column: this.column,
          famille: 'all',
          num: this.searchValue,
          comptabilite: this.comptabilite,
          mail_lot: this.mail_lot,
          no_proforma: true,
          nums: this.nums
        });
      });
    },
    async pagination(paginate) {
      this.page = paginate;
      await this.getFactures({
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        etat: this.statut,
        type: this.type,
        family: this.famille,
        vendeur_id: this.vendeur,
        acheteur_id: this.acheteur,
        categorie_id: this.categorie,
        per_page: this.perPage,
        page: this.page,
        order: this.order,
        column: this.column,
        famille: 'all',
        num: this.searchValue,
        comptabilite: this.comptabilite,
        no_proforma: true
      });
    },
    async listOrder(col, ord) {
      this.column = col;
      this.order = ord;
      await this.getFactures({
        date_debut: this.date_debut,
        date_fin: this.date_fin,
        etat: this.statut,
        type: this.type,
        family: this.famille,
        vendeur_id: this.vendeur,
        acheteur_id: this.acheteur,
        categorie_id: this.categorie,
        per_page: this.perPage,
        page: this.page,
        order: this.order,
        column: this.column,
        famille: 'all',
        num: this.searchValue,
        comptabilite: this.comptabilite,
        no_proforma: true
      });
    },

    setLocalStorageComptabilite() {
      localStorage.setItem(
        'refresh-cdg-th',
        JSON.stringify({
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          etat: this.statut,
          typeFacture: this.type,
          family: this.famille,
          mail_lot: this.mail_lot,
          vendeur_id: this.vendeur,
          acheteur_id: this.acheteur,
          categorie_id: this.categorie,
          per_page: this.perPage,
          page: this.page,
          order: this.order,
          column: this.column,
          famille: this.fam,
          num: this.searchValue,
          comptabilite: this.comptabilite
        })
      );
    }
  },
  filters: {
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY');
      }
    }
  },
  components: {
    IconFiltre: () => import('@/assets/img/IconFiltre.vue'),
    VisualisationFacture: () =>
      import('@/views/component/Facture/VisualisationFacture.vue'),
    InputFileExcelNumDossier: () =>
      import('../ThProject/componentRapportTh/inputFileExcelNumDossier.vue'),
    search: () => import('@/views/component/SearchInputComponent.vue'),
    Refresh
  },
  async mounted() {
    await this.getFamilles();
    await this.getTypeOfCompta();
    if (localStorage.getItem('refresh-cdg-th')) {
      let localData = JSON.parse(localStorage.getItem('refresh-cdg-th'));
      this.date_debut = localData.date_debut ? localData.date_debut : null;
      this.date_fin = localData.date_fin ? localData.date_fin : null;
      this.vendeur = localData.vendeur_id ? localData.vendeur_id : [];
      this.isLocalStorageVendeur = this.vendeur;
      this.acheteur = localData.acheteur_id ? localData.acheteur_id : [];
      this.isLocalStorageAcheteur = this.acheteur;

      this.categorie = localData.categorie_id ? localData.categorie_id : [];
      this.isLocalStorageCategorie = this.categorie;

      this.famille = localData.family ? localData.family : [];
      this.isLocalStorageFamille = this.famille;

      this.type = localData.typeFacture ? localData.typeFacture : [];
      this.isLocalStorageType = this.type;

      this.statut = localData.etat ? localData.etat : [];
      this.isLocalStorageStatut = this.statut;

      this.searchValue = localData.num ? localData.num : null;
      this.comptabilite = localData.comptabilite
        ? localData.comptabilite
        : null;
      this.isLocalStorageComptabilise = this.comptabilite;

      this.order = localData.order ? localData.order : 'DESC';
      this.column = localData.column ? localData.column : 'id';
      this.fam = localData.famille ? localData.famille : 'all';
      this.mail_lot = localData.mail_lot ? localData.mail_lot : [];
      this.isLocalStorageMailLot = this.mail_lot;
    } else {
      this.date_fin = moment(new Date()).format('YYYY-MM-DD');
      this.date_debut = moment(this.date_fin)
        .subtract(12, 'months')
        .format('YYYY-MM-DD');

      this.setLocalStorageComptabilite();
    }
    this.handleFilter(true);
    await this.getCategoriesFactureLibre();
    await this.getFilialsOfConnectedResponsable();
    await this.getSettingFilialeTh();
  }
};
</script>

<style lang="scss">
.popover-menu-refresh-facture {
  background-color: #704ad1;
  right: 7px;
  left: unset !important;
  top: 97px !important;
}
// .v-menu__content {
//   width: 18%;
//   overflow: auto;
// }
svg.svg-inline--fa.fa-paperclip.fa-w-14 {
  font-size: 14px;
  color: #704ad1;
}
.file_name {
  padding-top: 11px;
  color: #525252;
  font-size: 15px;
  font-weight: 600;
  padding-left: 10px;
}
.v-card__text.body-card {
  padding-top: 11px !important;
}

// .v-list {
//   .actions-menu {
//     .actions-list {
//       .v-sheet.theme--light {
//         padding: 0 !important;
//         background: #f1edfb !important;
//       }
//       .v-list-item.theme--light {
//         cursor: pointer;
//         padding-top: 5px;
//       }
//       .v-list-item {
//         align-items: center;
//         display: flex;
//         flex: 1 1 100%;
//         letter-spacing: normal;
//         min-height: 0px !important;
//         outline: none;
//         padding: 0 21px !important;
//         position: relative;
//         text-decoration: none;
//       }
//     }
//   }
// }
// .v-menu__content.theme--light.menuable__content__active {
//   min-width: 230px !important;
// }

// .v-list-item {
//   align-items: center;
//   display: flex;
//   flex: 1 1 100%;
//   letter-spacing: normal;
//   min-height: 0px !important;
//   outline: none;
//   padding: 0 16px;
//   position: relative;
//   text-decoration: none;
// }

// .v-list-item__action {
//   margin: 0px !important;
//   padding-bottom: 8px;
// }
// .actions-list.v-sheet.theme--light {
//   padding: 0 !important;
//   background: #f1edfb !important;
// }
// .v-list-item.theme--light {
//   cursor: pointer;
//   padding-top: 6px;
// }

// button.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--dark.v-size--default {
//   height: 28px;
//   min-width: 28px;
//   padding: 0 !important;
// }
.space-table-filter-refresh-fac {
  padding-top: 0px;
}
.table-refresh-fac {
  &.padding-table {
    padding-top: 19px;
  }
}
</style>
